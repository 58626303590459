import { useEffect, useState } from "react"
import { Edit, Trash } from "react-feather"
import { SnippedAdminDTO } from "../../dto/SnippedAdminDTO"
import { Snipped as Display } from '../Snipped/Snipped'
import JoditReact from "jodit-react-ts"
import 'jodit/build/jodit.min.css'
import { SnippedKind } from "../../dto/SnippedDTO"
import Input from "../Input/Input"
import ReactGPicker from "react-gcolor-picker"
import './Snipped.scss'
import FileUploader from "../MediaManager/FileUploader"
import RadioSelect from "../Input/RadioSelect"
import BGPosition from "../Input/BGPosition"
import Spinner from "../Spinner/Spinner"
import { QuickFileUpload } from "./Slot/QuickFileUpload"

enum Status {
  loading,
  edit,
  rename,
  done,
}

export function getAdminSnippedIdFromUrl(): number {
  const raw = window.location.href.match(/group\/([0-9]+)\/snipped\/([0-9]+)/)
  if (!raw) { return -1 }
  return parseInt(raw[2], 10)
}

export function Snipped(props: {
  id?: number,
  reload?: () => void
}) {
  const id = props.id || getAdminSnippedIdFromUrl()
  const [item] = useState(new SnippedAdminDTO({id: id}))
  const initBgColor = item.getPropVal1('style', 'bgcolor') || 'rgba(255, 255, 255, 1)'
  const initPadding = item.getPropVal1('style', 'padding') || '10'
  const initDisplayDuration = item.displayDuration || 10
  const [status, setStatus] = useState(Status.loading)
  const [Rerender, setRerender] = useState(0)
  const [currentId, setCurrentId] = useState(id)
  const [bgColor, setBgColor] = useState(initBgColor)
  const [padding, setPadding] = useState(initPadding)
  const [displayDuration, setDisplayDuration] = useState(initDisplayDuration)
  
  
  function reload(cache: 'true' | 'set') {
    console.log('reload Snipped Data', id)
    item.setId(id)
    setCurrentId(id)
    setStatus(Status.loading)
    item.getFromServer(cache).then(u => {
      setRerender(Rerender + 1)
      setStatus(Status.done)
    })
  }
  if (currentId !== id) {
    reload('set')
    
  }
  useEffect(() => {
    reload('set')
  }, [])
  console.log('Load Snipped', id, item.id, item)
  if (status === Status.loading) {
    return <div className={`flexGrow flexRows Screen ${Rerender}`}>
      Lade Snipped
      <Spinner />
    </div>
  }
  return <div className={`flexGrow admin-scroll-panel ${Rerender}`}>
    <div className=''>
      <h2>Schnipsel: {item.name}</h2>
    </div>
    <div className='w3-row'>
      <div className='w3-col s12 m6 l4 w3-border'>
        <button
          onClick={async () => {
            await item.props.removeProp('file', 'bg')
            setRerender(Rerender + 1)
          }}
        ><Trash /></button>
        <RadioSelect
          title='Hintergrund Größe'
          options={
            [
              {name: 'Füllend', value: 'cover'},
              {name: 'Ganzes Bild', value: 'contain'},
              {name: 'original', value: 'auto'},
            ]
          }
          default={item.getPropVal1('bg', 'size') || 'cover'}
          cb={async (value) => {
            await item.setProp('bg', 'size', value)
            setRerender(Rerender + 1)
          }}
        />
        <RadioSelect
          title='Hintergrund Wiederholen'
          options={
            [
              {name: 'Nicht wiederholen', value: 'no-repeat'},
              {name: 'Wiederholen', value: 'repeat'},
            ]
          }
          default={item.getPropVal1('bg', 'repeat') || 'no-repeat'}
          cb={async (value) => {
            await item.setProp('bg', 'repeat', value)
            setRerender(Rerender + 1)
          }}
        />
        <BGPosition
          externalId={`bg-position-${item.id}-${id}`}
          title='Hintergrund Ausrichtung'
          default={item.getPropVal1('bg', 'position') || 'top left'}
          cb={async (value) => {
            await item.setProp('bg', 'position', value)
            setRerender(Rerender + 1)
          }}
        />
        {item.kind !== SnippedKind.WEB2PIC &&
          <QuickFileUpload
            snipped={item}
            cb={() => {
              reload('set')
            }}
          />
          
        }
      </div>
      <div className='w3-col s12 m6 l4 w3-border'>
        Hintergrundfarbe
        <ReactGPicker
          value={initBgColor}
          onChange={(value: string) => {
            setBgColor(value as string)
            setRerender(Rerender + 1)
          }}
        />
        <button
          onClick={() => {
            item.setProp('style', 'bgcolor', bgColor)
            setRerender(Rerender + 1)
          }}
        >Save</button>
      </div>
      <div className='w3-col s12 m6 l4 w3-border'>

        <div>Seitenabstand</div>
        <Input
          value={padding}
          cb={(padding: string) => {
            setPadding(padding + '')
            setRerender(Rerender + 1)
          }}
        />
        <button
          onClick={async () => {
            await item.setProp('style', 'padding', padding + '')
            setRerender(Rerender + 1)
          }}
        >Save</button>

      <div>
        Anzeigedauer in Sekunden:
      </div>
      <Input
        value={initDisplayDuration + ''}
        cb={(raw: string) => {
          setDisplayDuration(parseFloat(raw))
          setRerender(Rerender + 1)
        }}
      />
      <button
        onClick={async () => {
          await item.setDisplayDuration(displayDuration)
          setRerender(Rerender + 1)
        }}
      >Save</button>
      </div>
    </div>
    <div className='w3-row'>
      <div className='w3-col s12 m12 l12 w3-border'>
        <h2>Editor</h2>
        <Editor
          key={`snipped-editor-${id}`}
          item={item}
          cb={() => { reload('set') }}
          className={``}
        />
      </div>
      <div className='w3-col s12 m12 l12 w3-border'>
      <h2>
        Vorschau
      </h2>
      <Display
        item={item}
        editorVersion={true}
        doRerender={Rerender}
      />
      </div>
    </div>
  </div>
}

function Editor(props: {
  item: SnippedAdminDTO,
  className: string,
  cb: () => void,
}) {
  const item = props.item
  const [value, setValue] = useState(item.value)
  console.log('Load Editor', item, item.value, value)
  switch(item.kind) {
    case SnippedKind.HTML:
      return <div className={props.className} style={item.generateStyle()}>
        <JoditReact
          key={`joedit-snipped-${item.id}`}
          onChange={(c) => {
            setValue(c)
          }}
          defaultValue={item.value}
        />
        <button
          className={`w3-btn ${item.value === value ? '': 'w3-green'}`}
          onClick={async () => {
            await item.setValue(value)
            props.cb()
          }}
        >Speichern</button>
      </div>
    case SnippedKind.IFRAME:
    case SnippedKind.WEB2PIC:
      return <div>
        <Input
          cb={(val) => {
            setValue(val)
          }}
          value={item.value}
        />
        <button
          className={`w3-btn ${item.value === value ? '': 'w3-green'}`}
          onClick={async () => {
            await item.setValue(value)
            props.cb()
          }}
        >Speichern</button>
      </div>
    case SnippedKind.CODE:
      return <div>
        <Input
          cb={(val) => {
            setValue(val)
          }}
          value={item.value}
          type='code'
        />
        <button
          className={`w3-btn ${item.value === value ? '': 'w3-green'}`}
          onClick={async () => {
            await item.setValue(value)
            props.cb()
          }}
        >Speichern</button>
      </div>
    default:
      return null
  }
}
