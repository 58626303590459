import { SlotAdminDTO } from "../../../dto/SlotAdminDTO";
import config from '../../../config.json'
import { SnippedAdminDTO } from "../../../dto/SnippedAdminDTO";
import { SnippedsAdminDTO } from "../../../dto/SnippedsAdminDTO";
import { getGroupIdFromUrl } from "../Group";
import { SnippedKind } from "../../../dto/SnippedDTO";
import { SlotsAdminDTO } from "../../../dto/SlotsAdminDTO";
import ScheduleAdminDTO from "../../../dto/ScheduleAdminDTO";
import { ScreenAdminDTO } from "../../../dto/ScreenAdminDTO";
import SchedulesAdminDTO from "../../../dto/SchedulesAdminDTO";
import { useRef } from "react";

export function QuickFileUpload(props: {
  slot?: SlotAdminDTO,
  snipped?: SnippedAdminDTO,
  schedule?: ScheduleAdminDTO,
  screen?: ScreenAdminDTO,
  cb: () => void,
}) {
  async function handleNewFile(transferInfo: {
    response: {
      id: number,
      name: string,
      filename: string
    },
    status: string
  }) {
    // Get or Create a Snipped
    let snipped: SnippedAdminDTO
    if (!props.snipped) {
      const snippeds = new SnippedsAdminDTO({groupId: getGroupIdFromUrl()})
      snipped = await snippeds.newItem({name: transferInfo.response.name, kind: SnippedKind.HTML})
    } else {
      snipped = props.snipped
    }

    // Attach File to Snipped
    await snipped.props.setPropVal({
      key1: 'file',
      key2: 'bg',
      value1: '',
      value2: '',
      fileId: transferInfo.response.id
    })

    // Do not affect snipped, if it is already there
    if (!props.snipped) {
      // Make the File to be displayed as whole without loosing picture information:
      await snipped.props.setPropVal({
        key1: 'bg',
        key2: 'size',
        value1: 'contain',
        value2: '',
        fileId: transferInfo.response.id
      })
  
      // Center the File
      await snipped.props.setPropVal({
        key1: 'bg',
        key2: 'position',
        value1: 'center center',
        value2: '',
        fileId: transferInfo.response.id
      })
    }

    // Only do this, if there is a Slot or Schedule
    if (props.slot || props.schedule || props.screen) {

      // Get or Create a Slot
      let slot: SlotAdminDTO
      if (!props.slot) {
        const slots = new SlotsAdminDTO({groupId: getGroupIdFromUrl()})
        slot = await slots.newItem({name: transferInfo.response.name})
      } else {
        slot = props.slot
      }
      // Attach Snipped to Slot
      await slot.addSnipped(snipped.id)

      // If there is a screen, and no schedule - create a schedule
      if (props.screen && !props.schedule) {
        const schedules = new SchedulesAdminDTO({screenId: props.screen.id, items: [], slots: []})  
        // const schedule = 
        await schedules.newSchedule({slotId: slot.id})
      }
    }

    // Call Callback
    props.cb()
  }
  const fileInput = useRef<HTMLInputElement | null>(null);
  return <div>
    <button className='w3-button w3-green' onClick={() => fileInput.current?.click()}>Quick Upload</button>
    <input
      style={{display: 'none'}}
      ref={fileInput}
      type="file"
      className='w3-button'
      onChange={(e) => {
        const file = (e.target as any).files[0];
        const formData = new FormData();
        formData.append('FileName', file);

        const target = `${config.apiPrefix}user/picture`
        fetch(target, {
          method: 'POST',
          body: formData,
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok.');
          }
          return response.json();
        })
        .then((result) => {
          console.log('Success:', result);
          handleNewFile(result)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      }}
    />
    
  </div>
}