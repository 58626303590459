
import Com from "../services/ComService";
import { IConstructor, ItemsDTO } from "./ItemsDTO";
import { IItem, SnippedAdminDTO } from "./SnippedAdminDTO";

export class SnippedsAdminDTO extends ItemsDTO {
  
  sourceUrl = 'admin/ugroup/snippeds'
  newItemUrl = 'admin/ugroup/snipped'

  public initItems(items: IItem[]) {
    this.items = items.map((i) => new SnippedAdminDTO(i))
  }

  public getItem(id: number) {
    return super.getItem(id) as SnippedAdminDTO
  }

  public async newItem(data: {name: string, kind: number}) {
    const newItemRaw = await Com(`PUT:${this.newItemUrl}`, {
      groupId: this.groupId,
      name: data.name,
      description: '',
      value: '',
      displayDuration: 10,
      kind: data.kind,
    }) as IItem
    console.log('newItemRaw', newItemRaw)
    const newItem = new SnippedAdminDTO(newItemRaw)
    newItem.props.init({parentId: newItem.id})
    console.log('newItemRaw converted', newItem)
    return newItem
  }
}

