// import React, { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import About from './components/Page/About'
import Layout from './components/Page/Layout'
// import LoggedOut from './components/Page/LoggedOut'
// import { LoginGuard } from './services/LoginService'
import Landing from './components/Page/Landing'
import AdminLayout from './components/Admin/AdminLayout'
import Root from './components/Page/Root'
import UserPreferences from './components/User/Preferences'
import UserLayout from './components/User/UserLayout'
import UserList from './components/Admin/UserList'
import GroupList from './components/Admin/GroupList'
import SetPasswordByToken from './components/User/SetPasswordByToken'
import Preferences from './components/Preference/Preferences'
import Impressum from './components/Page/Impressum'
import Datenschutz from './components/Page/Datenschutz'
import { Group } from './components/Admin/Group'
import { Screens } from './components/Admin/Screen/Screens'
import { Environment } from "./components/Admin/Environment"
import { Snippeds } from './components/Admin/Snippeds'
import { Slots } from './components/Admin/Slot/Slots'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Screen } from './components/Screen/Screen'
import { Screen as AdminScreen } from './components/Admin/Screen/Screen'
import FullscreenButton from './components/Input/FullscreenButton'
import { Slot } from './components/Admin/Slot/Slot'
import { Snipped } from './components/Admin/Snipped'
import { SnippedView } from './components/Admin/SnippedView'
// import broadcastService from './services/BroadcastService'



function App() {
  // const [loginStatus, setLoginStatus] = useState('')
  /*
  useEffect(() => {
    broadcastService.subscribe('appComponent', (key: string, r) => {
      if (key.search(/login|logout/) > -1) {
        setLoginStatus(r)
      }
    })
  })
  /*
  if (LoginGuard()) {
    return <LoggedOut></LoggedOut>
  }
  */
  return (
    <DndProvider backend={HTML5Backend}>
      <FullscreenButton />
      <Routes>
        <Route path="/screen/:hash" element={<Screen></Screen>}></Route>
        <Route path="/snipped/:snippedId" element={<SnippedView />} />
        <Route path="/" element={<Root />}>
          <Route path="user" element={<UserLayout />}>
            <Route index element={<UserPreferences />} />
            <Route path="groupList" element={<GroupList />} />
          </Route>
          <Route path="impressum" element={<Impressum />} />
          <Route path="datenschutz" element={<Datenschutz />} />
          <Route path="admin/group/:groupId" element={<Group />}>
            <Route path="snippeds" element={<Snippeds environment={Environment.root} />} />
            <Route path="slots" element={<Slots environment={Environment.root} />} />
            <Route path="screen/:screenId" element={<AdminScreen />} />
            <Route path="slot/:slotId" element={<Slot />} />
            <Route path="snipped/:snippedId" element={<Snipped />} />
            <Route path="screens" element={<Screens environment={Environment.root} />} />
            <Route index element={<Screens environment={Environment.root} />} />
          </Route>
          <Route path="admin" element={<AdminLayout />}>
            <Route index element={<GroupList />} />
            <Route path="groupList" element={<GroupList />} />
            <Route path="preferences" element={<Preferences />} />
            <Route path="userList" element={<UserList />} />
          </Route>
          <Route path="page/:name" element={<div>No Content</div>} />
          <Route path="*" element={<Layout />}>
            <Route path="setPassword/:hash" element={<SetPasswordByToken />} />
            <Route path="login" element={<Landing />} />
            <Route index element={<About />}/>
            <Route path="about" element={<About />} />
          </Route>
        </Route>
      </Routes>
    </DndProvider>
  )
}

export default App;
