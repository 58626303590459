import { useEffect, useState } from "react"
import { Plus } from "react-feather"
import { ScreensDTO } from "../../../dto/ScreensDTO"
import Input from "../../Input/Input"
import { getGroupIdFromUrl } from "../Group"
import ListItem from "../ListItem"
import '../List.scss'
import { Screen } from "./Screen"
import { Status } from "../Status"
import { useNavigate } from 'react-router-dom';
import { Environment } from "../Environment"

export function Screens(props: {
  environment?: Environment 
}) {
  const [items] = useState(new ScreensDTO({groupId: getGroupIdFromUrl()}))
  const [status, setStatus] = useState(Status.loading)
  const [Rerender, setRerender] = useState(0)
  const [newName, setNewName] = useState('')
  const [openId, setOpenId] = useState(-1)
  const navigate = useNavigate();
  function reload(cache: 'true' | 'set') {
    items.getFromServer(cache).then(u => {
      setRerender(Rerender + 1)
      setStatus(Status.done)
    })
  }
  useEffect(() => {
    reload('true')
  }, [])
  function modeClass() {
    switch(status) {
      case Status.single:
        return 'single-mode'
      default:
        return 'list-mode'
    }
  }
  
  return <div className={`ScreenAllParent flexRows ${modeClass()}`}>
    <div className='single h100'>
      
      <Screen
        id={openId}
        reload={() => {
          reload('set')
          setStatus(Status.done)
        }}
        setStatus={(status: Status) => {
          setStatus(status)
        }}
      />
    </div>
    <div className='ScreenListParent flexGrow flexRows list'>
      <div>
        <button
          onClick={() => {
            setStatus(Status.new)
          }}
          className={`w3-button w3-green`}
        ><Plus /></button>
        {
          status === Status.new &&
          <Input
            autofocus={true}
            placeholder={'Neuer Name'}
            cb={setNewName}
            onEnter={async (value) => {
              const newItem = await items.newItem({name: value})
              navigate(`/admin/group/${getGroupIdFromUrl()}/screen/${newItem.id}`)
              // setStatus(Status.done)
              // reload('set')
            }}
          />
        }
      </div>
      <div className={`flexGrow admin-scroll-panel`}>
        {
          items.getAll().map((i, index) => <ListItem
            key={`screens-list-item-${i.id}-${index}`}
            className={`${i.id === openId ? 'w3-blue' : ''}`}
            item={i}
            index={index}
            kind='screen'
            reload={() => {
              reload('set')
            }}
            open={() => {
              navigate(`/admin/group/${getGroupIdFromUrl()}/screen/${i.id}`)
              /*
              if (props.environment === Environment.root) {
                navigate(`/admin/group/${getGroupIdFromUrl()}/screen/${i.id}`)
              } else {
                setOpenId(i.id)
                setStatus(Status.single)
              }
              */
            }}
          />)
        }
      </div>
    </div>
  </div>
}

