import { useEffect, useState } from "react"
import { Plus, Search, X } from "react-feather"
import { SlotsAdminDTO } from "../../../dto/SlotsAdminDTO"
import Input from "../../Input/Input"
import { getGroupIdFromUrl } from "../Group"
import ListItem from "../ListItem"
import '../List.scss'
import '../Admin.scss'

import { Slot } from "./Slot"
import { Environment } from "../Environment"
import { useNavigate } from "react-router-dom"
import { SlotAdminDTO } from "../../../dto/SlotAdminDTO"
enum Status {
  loading,
  new,
  rename,
  single,
  done,
}

export function Slots(props: {
  className?: string,
  environment?: Environment 
}) {
  const [items] = useState(new SlotsAdminDTO({groupId: getGroupIdFromUrl()}))
  const [status, setStatus] = useState(Status.loading)
  const [Rerender, setRerender] = useState(0)
  const [newName, setNewName] = useState('')
  const [openId, setOpenId] = useState(-1)
  const [needle, setNeedle] = useState('')
  const navigate = useNavigate();
  function reload(cache: 'true' | 'set') {
    items.getFromServer(cache).then(u => {
      setRerender(Rerender + 1)
      setStatus(Status.done)
    })
  }
  useEffect(() => {
    reload('true')
  }, [])
  function modeClass() {
    switch(status) {
      case Status.single:
        return 'single-mode'
      default:
        return 'list-mode'
    }
  }
  console.log('SLOTS', items)
  return <div className={`flexGrow flexRows ${props.className || ''} ${modeClass()}`}>
    <div className='single'>
      <button
        className='w3-button list-button'
        onClick={() => {
          setStatus(Status.done)
        }}
      >&larr; Zurück zur Liste</button>
      <Slot
        id={openId}
        reload={() => {
          reload('set')
          setStatus(Status.done)
        }}
      />
    </div>
    <div className='list flexGrow flexRows'>
      <Input
        value={needle}
        cb={(value) => {
          setNeedle(value)
        }}
        onEnter={(value) => {
          setNeedle(value)
        }}
        placeholder='Suche'
        className="w3-padding"
      />
      {
        status !== Status.new &&
        <div>
          <button
            onClick={() => {
              setStatus(Status.new)
            }}
            className={`w3-button w3-green`}
          ><Plus /></button>
        </div>
      }
      {
        status === Status.new &&
        <div>
          <button
            onClick={() => {
              setStatus(Status.done)
            }}
            className={`w3-button w3-green`}
          ><X /></button>
          <Input
            autofocus={true}
            placeholder={'Neuer Name'}
            cb={setNewName}
            onEnter={async (value) => {
              const newItem = await items.newItem({name: value})
              navigate(`/admin/group/${getGroupIdFromUrl()}/slot/${newItem.id}`)
              // setStatus(Status.done)
              // reload('set')
            }}
          />
        </div>
      }
      <div className={`flexGrow admin-scroll-panel`}>
        {
          items.getAll(needle).map((i, index) => <ListItem
            key={`Slots-list-item-${i.id}-${index}`}
            className={`${i.id === openId ? 'w3-blue' : ''}`}
            item={i}
            index={index}
            kind='slot'
            reload={() => {
              reload('set')
            }}
            open={() => {
              navigate(`/admin/group/${getGroupIdFromUrl()}/slot/${i.id}`)
            }}
            snippeds={(i as SlotAdminDTO).snippeds}
            getSnippeds={async() => {
              await (i as SlotAdminDTO).getFromServer()
              return (i as SlotAdminDTO).snippeds
            }}
          />)
        }
      </div>
    </div>
  </div>
}

