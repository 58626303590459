import Com from "../services/ComService";
import { IItem, ItemDTO } from "./ItemDTO";

export class ItemAdminDTO extends ItemDTO {
  adminUrl: string = 'admin/item'

  constructor(data: IItem) {
    console.log('ItemAdminDTO constructor', data)
    super(data)
  }
  public async delete(cb?: () => void) {
    await Com(`DELETE:${this.adminUrl}`, {
      id: this.id
    })
    if (cb) {
      cb()
    }
  }

  public async rename(name: string, cb?: () => void) {
    this.name = name
    const data = await Com(`PATCH:${this.adminUrl}/rename`, this.get(), 3)
    this.init(data as IItem)
    if (cb) {
      cb()
    }
  }

  public async setProp(key1: string, key2: string, value1: string, value2?: string) {
    await this.props.setPropVal({key1: key1, key2: key2, value1: value1, value2: value2})
  }
}
