import Com from "../services/ComService";
import { ItemAdminDTO } from "./ItemAdminDTO";
import { IProp } from "./PropDTO";
import PropsDTO from "./PropsDTO";
import { generateStyle } from "./SnippedDTO";


export class SnippedAdminDTO extends ItemAdminDTO {
  adminUrl = 'admin/snipped'
  sourceUrl = 'admin/snipped'
  kind: number = 1
  displayDuration: number = 10
  value: string = ''
  props: PropsDTO = new PropsDTO({
    putTarget: 'admin/snipped/prop',
    propSource: 'admin/snipped/prop',
  })
  

  public init(data: IItem) {
    this.id = data.id
    this.name = data.name || ''
    this.description = data.description || ''
    this.kind = data.kind || 0
    this.displayDuration = data.displayDuration || -1
    this.value = data.value || ''
    if (this.id < 0) { return }
    if (!this.props) { return }
    this.props.init({
      parentId: data.id,
      props: data.props,
    })
  }

  public async getFromServer(cache?: string) {
    if (this.id < 0) { return }
    const cacheString = cache || 'true'
    const item = await Com(`POST:${cacheString}:${this.sourceUrl}`, {
      id: this.id
    }, 3) as any
    this.init(item)
  }

  public async setValue(value: string) {
    this.value = value
    await this.save()
  }

  public async setDisplayDuration(value: number) {
    this.displayDuration = value
    await this.save()
  }

  getDisplayDuration() {
    return this.displayDuration * 1000
  }

  public async save() {
    await Com('PATCH:admin/snipped', {
      id: this.id,
      name: this.name,
      description: this.description,
      value: this.value,
      displayDuration: this.displayDuration,
    })
  }

  generateStyle() {
    return generateStyle(this)
  }

  getIItem() {
    return {
      id: this.id,
      kind: this.kind,
      value: this.value,
      displayDuration: this.displayDuration,
      name: this.name
    }
  }

  getPreviewUrl() {
    return `/snipped/${this.id}`
  }
}

export interface IItem {
  id: number
  kind: number
  value: string
  displayDuration: number
  name: string
  description?: string
  version?: string
  groupId?: number
  props?: IProp[]
}