import Com from "../services/ComService";
import { ItemAdminDTO } from "./ItemAdminDTO";
import { IProp } from "./PropDTO";
import PropsDTO from "./PropsDTO";
import { ISchedule } from "./ScheduleAdminDTO";
import SchedulesAdminDTO from "./SchedulesAdminDTO";
import {IItem as ISlot } from "./SlotAdminDTO";
import {IItem as ISnipped } from "./SnippedAdminDTO";


export class ScreenAdminDTO extends ItemAdminDTO {
  adminUrl = 'admin/screen'
  sourceUrl = 'admin/screen'
  hash: string = ''
  props: PropsDTO = new PropsDTO({
    putTarget: 'admin/screen/prop',
    propSource: 'admin/screen/prop',
  })
  schedules: SchedulesAdminDTO = new SchedulesAdminDTO({
    screenId: this.id,
    items: [],
    slots: [],
    putTarget: 'admin/screen/prop',
  })
  
  constructor(data: IData) {
    console.log('ScreenAdminDTO constructor', data)
    super(data.screen)
    this.initScreen(data)
  }

  public async getFromServer(cache?: string) {
    if (this.id < 0) { return }
    const cacheString = cache || 'true'
    const data = await Com(`POST:${cacheString}:${this.sourceUrl}`, {
      id: this.id
    }, 3) as IData
    this.initScreen(data)
  }

  public initScreen(data: IData) {
    const item = data.screen
    if (this.id < 0) { return }
    this.hash = item.hash || this.hash
    this.name = item.name || this.name
    if (!this.props) { return }
    this.props.init({
      parentId: item.id,
      props: item.props,
    })
    if (!item.schedules) { return }
    
    this.schedules.init({
      screenId: this.id,
      items: item.schedules,
      slots: data.slots || []
    })
  }
}


export interface IScreen {
  id: number
  name?: string
  description?: string
  hash?: string
  version?: string
  groupId?: number
  scheduleIds?: number[]
  schedules?: ISchedule[]
  props?: IProp[]
}

export interface IData {
  screen: IScreen
  slots?: ISlot[]
  snippeds?: ISnipped[]
}
