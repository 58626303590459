import { useDrop } from "react-dnd"
import './DropZone.scss'
import { useState } from "react"

enum Status {
  standard,
  loading,
}

export function DropZone(props: {
  label: string,
  position: number,
  addItem?: (position: number, item: any) => Promise<void>,
  acceptKind: string,
  className?: string,
}) {
  let currentItem: any = ''
  const [status, setStatus] = useState(Status.standard)
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: props.acceptKind + '',
    canDrop: (props, monitor) => {
      currentItem = monitor.getItem()
      if (currentItem.pin) {
        return false
      }
      return true
    },
    drop: () => {
      if (props.addItem) {
        setStatus(Status.loading)
        return props.addItem(props.position, currentItem).then(() => {
          setStatus(Status.standard)
        })
      }
    },
    collect: monitor => ({
      // item: monitor.getItem(),
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop()
    }),
  }), [props.position])
  return <div
    ref={drop}
    className={`${props.className || ''} ptdropzone ${(isOver) ? 'ptdzhover' : ''} ${(canDrop) ? 'ptdzcanDrop' : 'w3-hide'} w3-center ${status === Status.loading ? 'drop-loading' : ''}`}
    data-html2canvas-ignore="true"
  >
    {props.label}
  </div>
}