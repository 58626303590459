import { Environment } from "./Environment"
import { getGroupIdFromUrl } from "./Group"
import { Plus, Search, X } from "react-feather"
import { Snipped } from "./Snipped"
import { SnippedsAdminDTO } from "../../dto/SnippedsAdminDTO"
import { SnippedDTO, snippedKinds } from "../../dto/SnippedDTO"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Input from "../Input/Input"
import ListItem from "./ListItem"
import './List.scss'
import { IItem } from "../../dto/ItemDTO"
enum Status {
  loading,
  new,
  rename,
  single,
  done,
}

export function Snippeds(props: {
  blackIds?: number[],
  className?: string,
  environment?: Environment 
}) {
  const [items] = useState(new SnippedsAdminDTO({groupId: getGroupIdFromUrl()}))
  const [status, setStatus] = useState(Status.loading)
  const [newKind, setNewKind] = useState(-1)
  const [Rerender, setRerender] = useState(0)
  const [newName, setNewName] = useState('')
  const [openId, setOpenId] = useState(-1)
  const [needle, setNeedle] = useState('')
  const navigate = useNavigate();
  function reload(cache: 'true' | 'set') {
    items.getFromServer(cache).then(u => {
      setRerender(Rerender + 1)
      setStatus(Status.done)
    })
  }
  useEffect(() => {
    reload('true')
  }, [])
  function modeClass() {
    switch(status) {
      case Status.single:
        return 'single-mode'
      default:
        return 'list-mode'
    }
  }
  
  return <div className={`flexGrow flexRows ${modeClass()}`}>
    <div className='single'>
      <button
        className='w3-button list-button'
        onClick={() => {
          setStatus(Status.done)
        }}
      >&larr; Zurück zur Liste</button>
      <Snipped
        id={openId}
        reload={() => {
          reload('set')
          setStatus(Status.done)
        }}
      />
    </div>
    <div className='list h100 flexRows'>
      <div>
      <Input
        value={needle}
        cb={(value) => {
          setNeedle(value)
        }}
        onEnter={(value) => {
          setNeedle(value)
        }}
        placeholder={'Suche'}
      />
      </div>
       {
        status !== Status.new &&
        <>
          <div>
            <button
              onClick={() => {
                setStatus(Status.new)
              }}
              className={`w3-button w3-green`}
            ><Plus /></button>
          </div>
        </>
      }

      { status === Status.new &&
        <div className="flex-start">
          <button
            onClick={() => {
              setStatus(Status.done)
            }}
            className={`w3-button w3-green`}
          ><X /></button>
          {
            snippedKinds.map(s => <button
              key={`snipped-kind-selector-${s.kind}`}
              onClick={() => {
                setNewKind(s.kind)
              }}
              className={`w3-button ${s.kind === newKind ? 'w3-green' : 'w3-grey'}`}
            >
              {s.name}
            </button>)
          }
          <Input
            autofocus={true}
            placeholder={'Neuer Name'}
            cb={setNewName}
            onEnter={async (value) => {
              await items.newItem({name: value, kind: newKind})
              setStatus(Status.done)
              reload('set')
            }}
          />
          <button
            onClick={async () => {
              const newItem = await items.newItem({name: newName, kind: newKind})
              navigate(`/admin/group/${getGroupIdFromUrl()}/snipped/${newItem.id}`)
              // setStatus(Status.done)
              // reload('set')
            }}
          >Ok</button>
        </div>
      }
      <div className={`flexGrow admin-scroll-panel ${props.className || ''}`}>
        {
          items.getAll(needle).filter(i => !(props.blackIds || []).some(b => i.id === b)).map((i, index) => 
          <ListItem
            key={`Snippeds-list-item-${i.id}-${index}`}
            className={`${i.id === openId ? 'w3-blue' : ''}`}
            item={i}
            index={index}
            kind='snipped'
            reload={() => {
              reload('set')
            }}
            open={() => {
              navigate(`/admin/group/${getGroupIdFromUrl()}/snipped/${i.id}`)
            }}
            snippeds={[i as unknown as SnippedDTO]}
          />)
        }
      </div>
    </div>
  </div>
}

