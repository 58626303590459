import { useEffect, useState } from "react"
import { SnippedDTO, SnippedKind } from "../../dto/SnippedDTO"
import './Snipped.scss'
import { Status } from "../Admin/Status"

export function Snipped(props: {
  item: SnippedDTO,
  editorVersion?: boolean,
  doRerender?: number,
  loadContent?: boolean,
  className?: string,
}) {
  const [item, setItem] = useState(props.item)
  const [status, setStatus] = useState(props.loadContent ? Status.loading : Status.done)
  useEffect(() => {
    setItem(props.item)
    if (props.loadContent) {
      props.item.getFromServer().then(() => {
        setStatus(Status.done)
      })
    }
  }, [props.item, props.item?.id, props.loadContent])
  

  if (!item) { return null }
  if (status === Status.loading) {
    return <div className={`${props.className || ''}`}>Laden ...</div>
  }
  const style = item.generateStyle()
  let className = props.className ? props.className : props.editorVersion ? 'snippedDemo' : 'snippedDisplay'
  
  return <>
    {
      (item.kind === SnippedKind.HTML) &&
      <div
        dangerouslySetInnerHTML={{__html: item.value}}
        className={className}
        style={style}
      ></div>
    }
    {
      (item.kind === SnippedKind.WEB2PIC) &&
      <div
        className={className}
        style={style}
      ></div>
    }
    {
      item.kind === SnippedKind.IFRAME &&
      <iframe
        title={`snipped-${item.id}`}
        src={`${prepareUrl(item.value)}`}
        width="1024"
        height="768"
        className={className}
        scrolling="no"
        style={style}
      ></iframe>
    }
  </>
}

function prepareUrl(url: string) {
  if (url.search('http') === -1) {
    return 'http://' + url
  }
  return url
}