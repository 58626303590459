import Com from "../services/ComService"
import { IProp } from "./PropDTO"
import PropsDTO from "./PropsDTO"

export class ItemDTO {
  sourceUrl = ''

  id: number = -1
  name: string = ''
  value: string =''
  description: string = ''
  props = new PropsDTO({})

  constructor(data: IItem) {
    console.log('ItemDTOConstructor, data', data)
    this.init(data)
  }

  public setId(id: number) {
    this.id = id
  }

  public init(data: IItem) {
    this.id = data.id
    this.name = data.name || this.name
    this.value = data.value || this.value
    this.description = data.description || this.description
    this.props.init({props: data.props})
  }

  public async getFromServer(cache?: string) {
    if (this.id < 0) { return }
    const cacheString = cache || 'true'
    const item = await Com(`POST:${cacheString}:${this.sourceUrl}`, {
      id: this.id
    }, 3) as any
    this.init(item)
  }

  public get() {
    return {
      id: this.id,
      name: this.name,
      value: this.value,
      description: this.description,
    }
  }

  public getPropVal1(key1: string, key2: string): string {
    console.log('props', this.props)
    return this.props.getPropVal1(key1,key2)
  }

  public getPreviewUrl(): string {
    return ''
  }
}

export interface IItem {
  id: number
  name?: string
  description?: string
  props?: IProp[]
  value?: string
}

