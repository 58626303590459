import { useEffect, useState } from "react"
import { Plus } from "react-feather"
import { useDrag, useDrop } from 'react-dnd'
import { ScreenAdminDTO } from "../../../dto/ScreenAdminDTO"
import Schedule from "../Schedule/Schedule"
import { Slots } from "../Slot/Slots"
import config from '../../../config.json'
import './Screen.scss'
import '../Admin.scss'

// var QRCode = require('qrcode.react')
import QRCode from 'qrcode.react'
import Com from "../../../services/ComService"
import { Status } from "../Status"
import Spinner from "../../Spinner/Spinner"
import { SetTransition } from "./SetTransition"
import ListItem from "../ListItem"
import { SlotAdminDTO } from "../../../dto/SlotAdminDTO"
import ScheduleAdminDTO from "../../../dto/ScheduleAdminDTO"
import { QuickFileUpload } from "../Slot/QuickFileUpload"

type DropItem = {
  id: number,
  kind: string,
}

export function getAdminScreenIdFromUrl(): number {
  const raw = window.location.href.match(/group\/([0-9]+)\/screen\/([0-9]+)/)
  if (!raw) { return -1 }
  return parseInt(raw[2], 10)
}

export function Screen(props: {
  id?: number,
  reload?: () => void,
  setStatus?: (status: Status) => void,
}) {
  const id = props.id || getAdminScreenIdFromUrl()
  const [item] = useState(new ScreenAdminDTO({screen: {id: id}}))
  const [status, setStatus] = useState(Status.loading)
  const [Rerender, setRerender] = useState(0)
  const [currentId, setCurrentId] = useState(id)
  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleAdminDTO | null>(null)
  
  
  
  function reload(cache: 'true' | 'set') {
    item.setId(id)
    setCurrentId(id)
    setStatus(Status.loading)
    item.getFromServer(cache).then(u => {
      setRerender(Rerender + 1)
      setStatus(Status.done)
    })
    
  }
  if (currentId !== id) {
    reload('set')
    
  }
  useEffect(() => {
    // reload('set')
    item.getFromServer().then(() => {
      console.log('Screen Info 2', item)
      setStatus(Status.done)
    })
  }, [item, item.id])

  const url = `${config.protocoll}://${window.location.hostname}${config.port}/screen/${item.hash}`

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'listItem',
    canDrop: (e, monitor) => {
      const dropItem: {id: number, kind: string} = monitor.getItem()
      return dropItem.kind === 'slot'
    },
    drop: (e, monitor) => {
      const dropItem = monitor.getItem() as DropItem
      console.log('do drop', item)
      item.schedules.newSchedule({
        slotId: dropItem.id
      }).then(() => {
        reload('set')
      })
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop()
    }),
  }), [item.id])
  console.log('Screen Info', item, item.name)
  if (status === Status.loading) {
    return <div className={`flexGrow flexRows Screen ${Rerender}`}>
      Lade Screen
      <Spinner />
    </div>
  }
  return <div className={`flexGrow flexRows Screen ${Rerender}`}>
    <div className='flexColumns qrCodeParent'>
      {
        props.setStatus ?
        <button
          className='w3-button list-button'
          onClick={() => {
            if (props.setStatus) {
              props.setStatus(Status.done)
            }
          }}
          >&larr; Screens
        </button> : null
      }
      <h2 className='flexGrow'>Bildschirm: {item.name}</h2>
      <QRCode
        className='qrCode'
        value={url}
        renderAs='svg'
        width='100'
        height='100'
        includeMargin={true}
      />
      <div className='single-top'>
        <div><button
          className='w3-button w3-btn w3-yellow'
          onClick={async () => {
            const version = await Com(`GET:admin/screen/${item.id}/setversion`, {}, 3) as {version: string}
            alert(`Neue Version ist ${version.version}. Die Clienten werden am Ende eines Loops benachrichtigt.`)
          }}
        >Sende Aktualisierungssignal</button></div>
        <div>
          <a href={url} target="_blank" rel="noreferrer">{url}</a>
        </div>
        <SetTransition screen={item} />
      </div>
    </div>
    <div className='flexColumns flexGrow timeRules w3-row'>
      <div className='h100 flexRows w3-col s12 m6 l6 w3-border-right positionRelative'>
        <h2 className='h2Admin'>Zeit-Regeln</h2>
        <QuickFileUpload
          screen={item}
          cb={() => {
            reload('set')
          }}
        />
        <div
          ref={drop}
          className={`newRuleDropZone w3-center w3-padding ${(isOver) ? 'w3-green' : 'w3-grey'} ${(canDrop) ? '' : 'w3-hide'}`}
        ><Plus /></div>
        <div className='admin-scroll-panel'>
          {
            item.schedules.getAll().map((s, index) => <ListItem
              key={`screen-schedule-${s.id}-listItem`}
              className={`${s.id === selectedSchedule?.id ? 'w3-green' : ''}`}
              item={{
                ...(new SlotAdminDTO(s.slot)),
                id: s.slot.id,
              }}
              kind='schedule'
              index={index}
              open={() => {

                setSelectedSchedule(s)
              }}
              remove={async () => {
                await item.schedules.removeSchedule(s.id)
                setRerender(Rerender + 1)
              }}
              dragging={false}
            />)
          }
        </div>
      </div>

      <div className='h100 flexRows flexGrow w3-col s12 m6 l6'>
        {
          selectedSchedule ? <Schedule
            schedule={selectedSchedule}
            key={`screen-schedule-${selectedSchedule.id}`}
            cb={() => {
              reload('set')
            }}
            close={() => {
              setSelectedSchedule(null)
            }}
          /> : <>
            <h2 className='h2Admin'>Verfügbare Seiten</h2>
            <Slots className='h100'/>
          </>
        }
      </div>
    </div>
  </div>
}

