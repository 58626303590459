import { useState } from "react"
import { ArrowDownCircle, ArrowUpCircle, Eye, Trash, X } from "react-feather"
import { useDrag } from 'react-dnd'
import Input from "../Input/Input"
import { Snipped } from "../Snipped/Snipped"
import { SnippedDTO } from "../../dto/SnippedDTO"

enum Status {
  loading,
  new,
  rename,
  single,
  done,
  preview,
}

export default function ListItem(props: {
  item: {
    id: number,
    name: string,
    delete?: () => void,
    rename?: (name: string, cb: () => void) => void,
    getPreviewUrl?: () => string,
  },
  kind: string,
  index: number,
  reload?: () => void,
  open?: () => void,
  remove?: () => void,
  className?: string,
  dragging?: boolean,
  up?: () => void,
  down?: () => void,
  snippeds?: SnippedDTO[],
  getSnippeds?: () => Promise<SnippedDTO[]>,
}) {
  // const item = props.item
  const [item] = useState(props.item)
  const [status, setStatus] = useState(Status.done)
  const [snippeds, setSnippeds] = useState<SnippedDTO[] | undefined>(props.snippeds)
  const [{isDragging}, drag] = useDrag(() => {
    return {
      type: 'listItem',
      item: {
        id: item.id,
        kind: props.kind,
      },
      collect: monitor => {
        return{
          isDragging: !!monitor.isDragging(),
        }
      },
      canDrag: () => {
        return !(props.dragging === false)
      }
    }
  }, [item.id, props.kind])
  const Buttons = [
    {
      display: <Eye />,
      fkt: async () => {
        console.log('props.item', props.item)
        if (props.item.getPreviewUrl || snippeds) {
          if (props.getSnippeds && status !== Status.preview) {
            setSnippeds(await props.getSnippeds())
          }
          setStatus(status === Status.preview ? Status.done : Status.preview)
          return
        }
        if (props.open) { props.open() }
      },
      title: 'Ansehen',
      show: !!props.open && props.item.getPreviewUrl
    },
    /*
    {
      display: <Edit />,
      fkt: () => {
        setStatus(Status.rename)
      },
      title: 'umbenennen',
      show: true
    },
    */
    {
      display: <ArrowUpCircle />,
      show: !!props.up,
      fkt: () => {
        if (props.up) {
          props.up()
        }
      }
    },
    {
      display: <ArrowDownCircle />,
      show: !!props.down,
      fkt: () => {
        if (props.down) {
          props.down()
        }
      }
    },
    {
      display: <Trash />,
      fkt: (item: {id: number, name: string, delete?: (fkt: () => void) => void}) => {
        if (item.delete) {
          if (!window.confirm(`${item.name} wirklich löschen?`)) { return }
          if (props.reload) {
            item.delete(props.reload)
          }
        }
      },
      title: 'löschen',
      show: !props.remove
    },
    {
      display: <X />,
      fkt: (item: {id: number, name: string}) => {
        if (!window.confirm(`${item.name} wirklich aus Liste entfernen?`)) { return }
        if (props.remove) { props.remove() }
      },
      title: 'entfernen',
      show: !!props.remove
    },
  ]
  return <div
      key={`item-${props.item.id}-${props.index}`}
      className={`w3-border ${props.className || ''} ${isDragging ? 'dragging' : ''}`}
      ref={drag}
    >
      <div className='flex'>
        {
          status !== Status.rename &&
          <div
            className='w3-button flex-grow'
            onClick={() => {
              if (props.open) { props.open() }
            }}
          >
            {props.item.name}
          </div>
        }
        {
          status === Status.rename &&
          <Input
            value={props.item.name}
            cb={() => {}}
            onEnter={(value) => {
              if (props.item.rename) {
                props.item.rename(value, () => {
                  setStatus(Status.done)
                })
              }
            }}
          />
        }
        {
          status === Status.preview && !snippeds &&props.item.getPreviewUrl &&
          <div style={{ width: '200px', height: '150px', overflow: 'hidden' }}>
            <iframe
              title={`Preview ${props.item.name}`}
              src={props.item.getPreviewUrl()}
              style={{ width: '1024px', height: '768px', transform: 'scale(0.19)', transformOrigin: '0 0' }}
            ></iframe>
          </div>
        }
        <div>
          {
            Buttons.map((b, i) => {
              if (!b.show) { return null }
              return <button
                key={`button-${i}`}
                className={`w3-button w3-border pointer`}
                title={b.title}
                onClick={() => b.fkt(props.item)}
              >
                {b.display}
              </button>
            })
          }
        </div>
      </div>
      {
        status === Status.preview && snippeds &&
        <div className='flex-end'>
          {
            snippeds.map(s => 
              <div className='snippedPreview w3-border'>
                <Snipped
                  key={s.id}
                  item={s}
                  editorVersion={true}
                  className='snippedDisplay snippedThumb'
                  loadContent={true}
                />
              </div>
            )
          }
        </div>
      }
    </div>
}