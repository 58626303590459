import Com from "../services/ComService"
import ScheduleAdminDTO, { ISchedule } from "./ScheduleAdminDTO"
import {IItem as ISlot } from "./SlotAdminDTO";

export default class SchedulesAdminDTO {
  items: ScheduleAdminDTO[] = []
  screenId: number = -1
  putTarget: string

  constructor(data: ISchedules) {
    this.init(data)
    this.putTarget = data.putTarget || ''
  }
  init(data: ISchedules) {
    this.screenId = data.screenId || this.screenId
    this.items = data.items.map(i => new ScheduleAdminDTO(i, data.slots))
  }

  getAll() {
    return this.items
  }

  async newSchedule(data: {
    slotId: number
  }) {
    await Com('PUT:admin/schedule', {
      screenId: this.screenId,
      slotId: data.slotId,
      start: new Date(),
      end: null,
      priority: 10,
      displayDuration: -1,
      repeats: -1,
      weekdays: 'ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
      startTime: '00:00',
      endTime: '24:00',
      active: true,
    })
  }

  public async removeSchedule(id: number) {
    const item = this.items.find(i => i.id === id)
    if (item) {
      await item.delete()
      this.items = this.items.filter(i => i.id !== id)
    }
  }
}

interface ISchedules {
  items: ISchedule[]
  slots: ISlot[]
  screenId: number
  putTarget?: string
}