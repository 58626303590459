import { useState } from "react";
import { ScreenAdminDTO } from "../../../dto/ScreenAdminDTO";

export function SetTransition({ screen }: { screen: ScreenAdminDTO }) {
  const [transitionKind, setTransitionKind] = useState(
    screen.getPropVal1('transition', 'kind') || 'slide',
  );

  const kinds = [
    { name: 'Schieben', key: 'slide' },
    { name: 'Überblenden', key: 'fade' },
    { name: 'Klicken', key: 'instant' },
  ];

  return (
    <div>
      Übergangstyp:
      {kinds.map((kind, index) => (
        <button
          key={index}
          className={`${kind.key === transitionKind ? 'w3-green' : 'w3-gray'} w3-button`}
          onClick={() => {
            setTransitionKind(kind.key);
            screen.setProp('transition', 'kind', kind.key);
          }}
        >
          {kind.name}
        </button>
      ))}
    </div>
  );
}
