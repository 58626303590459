import { useEffect, useState } from "react"
import { SnippedAdminDTO } from "../../dto/SnippedAdminDTO"
import { Snipped as Display } from '../Snipped/Snipped'
import 'jodit/build/jodit.min.css'
import './Snipped.scss'
import Spinner from "../Spinner/Spinner"
import { SnippedDTO } from "../../dto/SnippedDTO"

enum Status {
  loading,
  edit,
  rename,
  done,
}

export function getAdminSnippedIdFromUrl(): number {
  const raw = window.location.href.match(/snipped\/([0-9]+)/)
  if (!raw) { return -1 }
  return parseInt(raw[1], 10)
}

export function SnippedView(props: {
  id?: number,
  reload?: () => void
}) {
  const id = props.id || getAdminSnippedIdFromUrl()
  const [item] = useState(new SnippedAdminDTO({id: id}))
  const [status, setStatus] = useState(Status.loading)

  function reload(cache: 'true' | 'set') {
    item.setId(id)
    setStatus(Status.loading)
    item.getFromServer(cache).then(u => {
      setStatus(Status.done)
    })
  }

  useEffect(() => {
    reload('set')
  }, [])
  
  console.log('Load Snipped', id, item.id, item)
  if (status === Status.loading) {
    return <div className={`flexGrow flexRows Screen`}>
      Lade Snipped
      <Spinner />
    </div>
  }
  return <Display
    item={new SnippedDTO(item.getIItem())}
    editorVersion={true}
  />
  
}
