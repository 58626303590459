export function RemoveDoublicates(arr: {id: number}[]) {
  let ids: number[] = []
  return arr.filter(a => {
    if (ids.indexOf(a.id) > -1) { return false }
    ids.push(a.id)
    return true
  })
}

export function UniqueIds(arr: number[]) {
  let ids: number[] = []
  return arr.filter(a => {
    if (ids.indexOf(a) > -1) { return false }
    ids.push(a)
    return true
  })
}

export function SortBySortorder(arr: {id: number}[], sortOrder: string): any[] {
  const sortIds = sortOrder.split(';').map(id => parseInt(id, 10))
  const sortedArr = arr.sort((a, b) => {
    const indexA = sortIds.indexOf(a.id);
    const indexB = sortIds.indexOf(b.id);
    if (indexA === -1 && indexB === -1) return 0;
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  });

  return sortedArr;

}

export function AddItemToSortorder(arr: {id: number}[], sortOrder: string, item: {id: number}, position: number): string {
  const sorted = SortBySortorder(arr, sortOrder)
  const added = [...sorted.slice(0, position), item, ...sorted.slice(position)]
  return added.map(i => i.id).join(';')
}

export function MoveItemToPosition(arr: {id: number}[], sortOrder: string, item: {id: number}, position: number): string {
  const sorted = SortBySortorder(arr, sortOrder)
  const itemIndex = sorted.findIndex(i => i.id === item.id)
  const correctedArray = [...sorted.slice(0, itemIndex), ...sorted.slice(itemIndex + 1)]
  correctedArray.splice(position, 0, item)
  return correctedArray.map(i => i.id).join(';')
}